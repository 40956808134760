<template>
	<div>
		<div class="b-head-block">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<h1 class="b-title b-title--h1">Клинические рекомендации</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{ 'b-filter': true, open: open }">
					<button @click="open = !open" class="b-filter__button">
						<iconSvg v-if="!open" class="b-icon b-icon--filter icon-filter icon" />
						<span v-if="!open">Фильтр записей</span>

						<iconCloseSvg v-if="open" class="b-icon b-icon--close icon-close icon" />
						<span v-if="open">Скрыть</span>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
								<!-- Номер -->
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
									<input
										id="search-filter-number"
										placeholder="Номер"
										v-model="objFilters.filterNumber"
										class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"
										type="search"
										name="search-filter-name"
									/>
									<iconSearchSvg class="b-icon b-icon--table-search" />
									<label class="b-input__label" for="search-filter">Номер</label>
								</div>
								<!-- Разработчик -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										class="style-chooser"
										placeholder="Разработчик"
										:reduce="(obj) => obj.id"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.searchResultDev"
										:options="сompaniesList"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
									</v-select>
								</div>
								<!-- Заболевание -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										class="style-chooser"
										placeholder="Заболевание"
										:filterable="false"
										@open="onOpen"
										@close="onClose"
										@search="(query) => (search = query)"
										:clearable="false"
										:closeOnSelect="false"
										multiple
										v-model="objFilters.searchResult"
										:options="paginated"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.
											</template>
										</template>

										<template #list-footer>
											<li ref="load" class="loader" v-show="hasNextPage">Список прогружается...</li>
										</template>
									</v-select>
								</div>
								<!-- Код МКБ-10 -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										placeholder="Код МКБ-10"
										:filterable="false"
										@open="onOpenMkb"
										@close="onCloseMkb"
										@search="(query) => (searchMkb = query)"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.searchResultMkb"
										:options="paginatedMkb"
										label="code"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
										<template #list-footer>
											<li ref="load" class="loader" v-show="hasNextPage">Список прогружается...</li>
										</template>
									</v-select>
								</div>
								<!-- Решение НПС -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										placeholder="Решение НПС"
										:reduce="(obj) => obj.id"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.searchResultNpc"
										:options="npcList"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
									</v-select>
								</div>
							</div>
							<div class="select-group">
								<!-- Дата от -->
								<div class="b-input b-input--dual b-input--clinical b-input--filter-date">
									<input :max="toJSONLocal(dateTo ? dateTo : null)" id="dateFrom" type="date" name="name" v-model="dateFrom" placeholder="От" class="date-from" />
									<label class="b-input__label" for="id"></label>
								</div>
								<!-- Дата до -->
								<div class="b-input b-input--dual b-input--clinical b-input--filter-date">
									<input
										:min="toJSONLocal(dateFrom ? dateFrom : '1970-01-01')"
										:max="toJSONLocal()"
										id="dateTo"
										type="date"
										name="name"
										v-model="objFilters.dateTo"
										placeholder="До"
										class="date-from"
									/>
									<label class="b-input__label" for="id"></label>
								</div>

								<!-- Статус -->
								<div class="b-select b-select--dual b-select--clinical b-select--status">
									<v-select
										class="style-chooser"
										placeholder="Статус"
										:reduce="(obj) => obj.id"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.statuses"
										:options="statusesList"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
									</v-select>
								</div>
								<!-- Связь с Перечнем -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										placeholder="Связь с Перечнем"
										:reduce="(obj) => obj.id"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.serviceTypes"
										:options="serviceTypesList"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
									</v-select>
								</div>
								<!-- Возрастная группа -->
								<div class="b-select b-select--dual b-select--clinical b-select--age">
									<v-select
										placeholder="Возрастная группа"
										:reduce="(obj) => obj.id"
										:closeOnSelect="false"
										:clearable="false"
										multiple
										v-model="objFilters.ageGroups"
										:options="ageGroupsList"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												Поиск не дал результатов <em>{{ search }}</em
												>.</template
											>
										</template>
									</v-select>
								</div>
							</div>
							<button @click="searchFunc()" class="b-button b-button--fill">
								<span>Поиск</span>
							</button>
							<button @click="reset()" class="ml-2 b-button b-button--cancel">
								<span>Сбросить фильтр</span>
							</button>
						</div>
					</form>
					<div style="color: blue;" v-if="viewText" >Для применения фильтра нажмите на кнопку Поиск</div>
				</div>

				<!-- Установить дату заседания -->

				<div class="b-date-select" v-if="getUserInfo && getUserInfo.role.id == 6">
					<p class="b-date-select__description" v-if="!dateVoting.isSaved">Установить дату заседания</p>
					<p class="b-date-select" v-if="dateVoting.isSaved">
						Дата заседания: {{ `${new Date(dateVoting.date).getDate()}-${new Date(dateVoting.date).getMonth()+1 < 10 ? 0 : ''}${new Date(dateVoting.date).getMonth() + 1}-${new Date(dateVoting.date).getFullYear()}` }}
					</p>
					<input id="dateVoting" type="date" name="name" :min="minCurrentDate" placeholder="Дата заседания" class="date-from" v-model="dateVoting.date" v-if="!dateVoting.isSaved" />
					<button @click="validateDateVoting" class="b-button b-button--fill" v-if="!dateVoting.isSaved">Сохранить</button>
					<div class="b-date-select__warning" v-if="showSaveDateWarning" @click.self="showSaveDateWarning = false">
						<div class="b-date-select__content">
							<p class="b-date-select__warning-text">
								Вы уверены? <br />
								Изменить дату невозможно.
							</p>
							<button @click="saveDateVoting" class="b-button b-button--fill">Да, сохранить</button>
						</div>
					</div>
				</div>

				<div class="b-table-list b-table-list--summary">
					<div class="b-table-list__top">
						<div class="b-table-list__left">
							<div class="b-table-list__all">
								Всего записей:<span>{{ allStroke }}</span>
							</div>
							<div class="b-table-list__scroll b-table-list__scroll--filter js-horizontal-scroll">
								<ul class="b-table-list__list-record">
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(0)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 0
											}"
											href="javascript:void(0);"
											title="Все"
											>Все</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(1)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 1
											}"
											href="javascript:void(0);"
											title="Уведомления"
											>Уведомления</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(2)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 2
											}"
											href="javascript:void(0);"
											title="Заявления"
											>Заявления</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(3)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 3
											}"
											href="javascript:void(0);"
											title="Заявления"
											>Направления</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(4)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 4
											}"
											href="javascript:void(0);"
											title="Заявления"
											>Заключения</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(5)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 5
											}"
											href="javascript:void(0);"
											title="Решения"
											>Решения</a
										>
									</li>
									<li class="b-table-list__item-record">
										<a
											@click="filterStage(6)"
											:class="{
												'b-link': true,
												'b-link--record': true,
												active: clickFilter == 6
											}"
											href="javascript:void(0);"
											title="Решения"
											>КР</a
										>
									</li>
								</ul>
							</div>
						</div>
						<div class="b-table-list__right">
							<a @click="downloadReports" class="b-link b-link--report" href="javascript:void(0);" title="">
								<iconDownloadSvg class="b-icon b-icon--report icon-download" />
								<span class="mobile">Отчет</span>
								<span style="text-transform: none" class="desktop">Получить отчет</span>
							</a>
						</div>
					</div>
					<div class="b-table-list__table">
						<div style="position: relative; min-height: 300px" class="b-table-list__scroll b-table-list__scroll--table js-horizontal-scroll">
							<div v-if="isLoading" class="ring-load">
								<RingLoader class="ring-load-loading" color="#04092a" :size="160" />
							</div>
							<table>
								<thead>
									<tr>
										<th style="width: 100px"></th>
										<th onselectstart="return false" onmousedown="return false" v-for="(item, key) in columns" :key="'B'+key">
											<span @click="sortFunc(item)" style="cursor: pointer">{{ item.name }}</span>

											<div v-show="checkColumn == item.nameEng" class="b-table-list__arrow">
												<button v-if="item.sort" class="b-table-list__top-button">
													<iconSortupSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
												<button v-else class="b-table-list__bottom-button">
													<iconSortdownSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, key) in list" :key="'C'+key" @click="nextPageEV(getUserInfo.role.id, row.status.id, row.stage.id, row.id, row.developers)">
										<!-- <tr v-for="row in list" @click="nextPageEV(getUserInfo.role.id,row.status.id,row.stage.id, row.id)" v-show="row.status.id==15||row.status.id==16"> -->
										<!-- {{
                      row.status.id
                    }} -->
										<!-- <br />
                    {{
                      row.modifiedOn
                    }} -->
										<td style="width: 100px">
											<div class="new-tag" v-if="row.newFlag">Новая</div>
											<div class="old-tag" v-if="row.status.id == 35">Просроченая</div>
										</td>
										<td>
											<a href="javascript:void(0);" title>{{ row.stage ? row.stage.name : '' }} №{{ row.number ? row.number : '' }}</a>
											<time v-if="row.stage.id == 1">{{ row.dateStage1 ? new Date(row.dateStage1).toLocaleDateString() : '' }}</time>
											<time v-else>{{ row.dateStage2 ? new Date(row.dateStage2).toLocaleDateString() : '' }}</time>
										</td>
										<td>
											{{ row.serviceType ? row.serviceType.name : '&mdash;' }}
										</td>
										<td style="width: 70px">
											{{ row.name}}
										</td>
										<td>
											{{ row.mkb ? row.mkb.name : '' }}
											{{
												`${
													row.ageGroups.length !== 0
														? '(' +
														  Array.prototype.map
																.call(row.ageGroups, function (item) {
																	return item.name;
																})
																.join(', ') +
														  ')'
														: ''
												}`
											}}
										</td>
										<td>
											<ul style="padding: 0">
												<li v-for="(item, key2) in row.mkbCodes" :key="'D'+key2">
													<b>{{ item.code }}</b> &mdash; {{ item.name }}
												</li>
											</ul>
										</td>
										<td>
											<span>
												{{ row.developer ? row.developer.name : '&mdash;' }}
											</span>
										</td>
										<td>
											<span>{{ row.status ? row.status.name : '' }}</span>
										</td>
										<td>
											{{ row.npsDecision ? row.npsDecision.name : '&mdash;' }}
										</td>
									</tr>
								</tbody>
							</table>

							<center v-if="list.length == 0 && !isLoading" style="text-align: center">
								<p>Данных нет...</p>
							</center>
						</div>
					</div>
				</div>
				<div v-if="!isLoading" class="b-wrapper-page__bottom">
					<div class="b-pagination">
						<ul class="b-pagination__list">
							<li class="b-pagination__item-prev">
								<a
									@click="prevPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber == 0
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAngleleftSvg class="b-icon icon-angle-left" />
								</a>
							</li>

							<li class="b-pagination__item">
								<span>{{ resultCount > 0 ? pageNumber + 1 : 0 }} из {{ resultCount }} стр</span>
							</li>

							<li class="b-pagination__item-next">
								<a
									@click="nextPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber >= resultCount - 1
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAnglerightSvg class="b-icon icon-angle-left" />
								</a>
							</li>
						</ul>
					</div>
					<div class="b-number-page">
						<div class="b-number-page__text">
							Показать кол-во строк
							<span></span>
						</div>
						<!-- <div class="b-number-page__text">
							из<span>{{allStroke}}</span>

						</div> -->
						<div class="b-number-page__text">
							<select @change="updateListEvent()" style="width: 40px" v-model="size">
								<option :value="5">5</option>
								<option :value="10">10</option>
								<option :value="20">20</option>
								<option :value="30">30</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import iconSvg from '../../assets/icons/svg/filter.svg';
import iconCloseSvg from '../../assets/icons/svg/close.svg';
import iconSearchSvg from '../../assets/icons/svg/search.svg';
import iconAngleDownSvg from '../../assets/icons/svg/angle-down.svg';
import iconAngleleftSvg from '../../assets/icons/svg/angle-left.svg';
import iconAnglerightSvg from '../../assets/icons/svg/angle-right.svg';
import iconDownloadSvg from '../../assets/icons/svg/download.svg';
import iconSortdownSvg from '../../assets/icons/svg/sort-down.svg';
import iconSortupSvg from '../../assets/icons/svg/sort-up.svg';
import iconEditSvg from '../../assets/icons/svg/edit.svg';
import iconEyeSvg from '../../assets/icons/svg/eye.svg';
import orderBy from 'lodash.orderby';
import { RingLoader } from '@saeris/vue-spinners';

import Dropdown from '../../components/Dropdown/index';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
	name: 'listCr',
	components: {
		RingLoader,
		Dropdown,
		iconEditSvg,
		iconEyeSvg,
		iconSvg,
		iconSearchSvg,
		iconAngleDownSvg,
		iconCloseSvg,
		iconDownloadSvg,
		iconSortdownSvg,
		iconSortupSvg,
		iconAngleleftSvg,
		iconAnglerightSvg
	},
	computed: {
		...mapGetters({
			media: 'layout/getMedia',
			getUserInfo: 'auth/getUserInfo',
			apiUrl: 'apiUrl',
			getFilter: 'auth/getFilter'
		}),
		filtered() {
			return this.mkbList.filter((f) => f.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		paginated() {
			return this.filtered.slice(0, this.limit);
		},
		hasNextPage() {
			return this.paginated.length < this.filtered.length;
		},

		filteredMkb() {
			return this.mkbList.filter((f) => f.code.includes(this.searchMkb.toUpperCase()));
		},
		paginatedMkb() {
			return this.filteredMkb.slice(0, this.limitMkb);
		},
		hasNextPageMkb() {
			return this.paginatedMkb.length < this.filteredMkb.length;
		},
        authCompanyId() {
            let user = JSON.parse(localStorage.getItem('userInfo'));

            return user.userInfo.company.id
        }


	},
	watch: {
		'objFilters.filterNumber'(val){
			if(val){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.searchResult'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.searchResultMkb'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.searchResultDev'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.statuses'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.dateFrom'(val){
			if(val){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.searchResultNpc'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.ageGroups'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.serviceTypes'(val){
			if(val.length > 0){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
		'objFilters.dateTo'(val){
			if(val){
				this.viewText = true
			}
			else{
				this.viewText = false
			}
		},
	},
	data: () => ({
        minCurrentDate: new Date().toISOString().substring(0, 10),
		obj: {},
		searchBtnFunc: false,
		isSearch: false,
		isLoading: true,
		allStroke: 0,
		viewText: false,
		dateVoting: {
			date: '',
			isSaved: false
		},
		showSaveDateWarning: false,
		clickFilter: 0,
		open: false,
		checkColumn: 'number',
		filterNumber: '',
		options: ['1', '2', '3'],
		columns: [
			{
				name: 'Стадия, номер и дата',
				sort: false,
				nameEng: 'number',
				click: true
			},
			{
				name: 'Связь с Перечнем',
				sort: false,
				nameEng: 'serviceTypeName',
				click: false
			},
			{
				name: 'Название КР',
				sort: false,
				nameEng: 'name',
				click: false
			},
			{
				name: 'Заболевание и возрастная группа',
				sort: false,
				nameEng: 'mkbName',
				click: false
			},
			{ name: 'МКБ-10', sort: false, nameEng: 'mkbCode', click: false },
			{
				name: 'Разработчик',
				sort: false,
				nameEng: 'developerName',
				click: false
			},
			{ name: 'Статус', sort: false, nameEng: 'statusName', click: false },
			{
				name: 'Решение НПС',
				sort: false,
				nameEng: 'npsDecisionName',
				click: false
			}
		],
		list: [],
		objFilters: {
			filterNumber: '',
			searchResult: [],
			searchResultMkb: [],
			searchResultDev: [],
			statuses: [],
			dateFrom: null,
			dateTo: null,
			searchResultNpc: [],
			ageGroups: [],
			serviceTypes: [],
		},
		pageNumber: 0,
		npcList: [],
		objOldFilters: {},
		сompaniesList: [],
		serviceTypes: [],
		ageGroups: [],
		statuses: [],
		dateFrom: null,
		dateTo: null,
		size: 5,
		resultCount: 0,
		mkbList: [],
		flagNext: false,
		//поиск по названию
		observer: null,
		limit: 10,
		search: '',
		searchResult: [],
		//поиск по мкб названию
		observerMkb: null,
		limitMkb: 10,
		searchMkb: '',
		searchResultMkb: [],
		searchResultDev: [],
		searchResultNpc: [],
		serviceTypesList: [],
		ageGroupsList: [],
		statusesList: []
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		...mapMutations({
			changeFilter: 'auth/changeFilter',
			resFilToLS: 'auth/resFilToLS'
		}),
		async onOpen() {
			if (this.hasNextPage) {
				await this.$nextTick();
				this.observer.observe(this.$refs.load);
			}
		},
		onClose() {
			this.observer.disconnect();
		},
		async infiniteScroll([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent;
				const scrollTop = target.offsetParent.scrollTop;
				this.limit += 10;
				await this.$nextTick();
				ul.scrollTop = scrollTop;
			}
		},
		async onOpenMkb() {
			if (this.hasNextPageMkb) {
				await this.$nextTick();
				this.observerMkb.observe(this.$refs.load);
			}
		},
		onCloseMkb() {
			this.observerMkb.disconnect();
		},
		async infiniteScrollMkb([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent;
				const scrollTop = target.offsetParent.scrollTop;
				this.limitMkb += 10;
				await this.$nextTick();
				ul.scrollTop = scrollTop;
			}
		},
		sortFunc(e) {
			//очищаем всем флаг сортировки
			this.columns.forEach((el) => (el.click = false));

			this.isLoading = true;
			e.sort = !e.sort;
			e.click = true;
			this.checkColumn = e.nameEng;

			this.apiMethods(
				{ columnName: e.nameEng, orderOperation: e.sort ? 'asc' : 'desc' },
				{
					offsetRowCount: this.pageNumber !== 0 ? this.pageNumber * this.size : 0,
					fetchRowCount: this.size,
					use: true
				},
				this.clickFilter !== 0 ? this.clickFilter : null
			);
		},
		pageCount(count) {
			this.resultCount = Math.ceil(count / this.size);
		},
		nextPage() {
			this.pageNumber++;

			let columnsItem = this.columns.find((f) => f.click);

			console.log(columnsItem);

			this.apiMethods(
				{
					columnName: columnsItem.nameEng,
					orderOperation: columnsItem.sort ? 'asc' : 'desc'
				},
				{
					offsetRowCount: this.pageNumber !== 0 ? this.pageNumber * this.size : 0,
					fetchRowCount: this.size,
					use: true
				},
				this.clickFilter !== 0 ? this.clickFilter : null
			);
		},
		prevPage() {
			this.pageNumber--;

			let columnsItem = this.columns.find((f) => f.click);
			this.apiMethods(
				{
					columnName: columnsItem.nameEng,
					orderOperation: columnsItem.sort ? 'asc' : 'desc'
				},
				{
					offsetRowCount: this.pageNumber !== 0 ? this.pageNumber * this.size : 0,
					fetchRowCount: this.size,
					use: true
				},
				this.clickFilter !== 0 ? this.clickFilter : null
			);
		},
		searchFunc() {
			if (this.viewText) {
				this.objOldFilters = JSON.parse(JSON.stringify(this.objFilters))
				console.log(this.objOldFilters);
			}
			this.isLoading = true;
			this.isSearch = true;
			this.searchBtnFunc = true;

			let columnsItem = this.columns.find((f) => f.click);
			this.apiMethods(
				{
					columnName: columnsItem.name,
					orderOperation: columnsItem.sort ? 'asc' : 'desc'
				},
				{ offsetRowCount: 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			);
			this.pageNumber = 0;
		},
		reset() {
			this.objOldFilters = {}
			this.objFilters = {
			filterNumber: '',
			searchResult: [],
			searchResultMkb: [],
			searchResultDev: [],
			statuses: [],
			dateFrom: null,
			dateTo: null,
			searchResultNpc: [],
			ageGroups: [],
			serviceTypes: [],
		}
		this.viewText = false
			this.apiMethods(
				null,
				{
					offsetRowCount: this.pageNumber !== 0 ? this.pageNumber * this.size : 0,
					fetchRowCount: this.size,
					use: true
				},
				null,
				true
			);
		},
		apiMethods(orderByColumn, OffSetFetch, stageId, resetFilters) {
			if (resetFilters) this.resFilToLS();

			if (!this.searchBtnFunc) {
				for (let key in this.getFilter) {
					if (Array.isArray(this.getFilter[key]) && this.getFilter[key].length > 0) {
						this.isSearch = true;
						if (key == 'number') this.objFilters.filterNumber = this.getFilter[key];
						if (key == 'stageId') this.objFilters.clickFilter = this.getFilter[key];
						if (key == 'statusId') this.objFilters.statuses = this.getFilter[key];
						if (key == 'serviceTypeId') this.objFilters.serviceTypes = this.getFilter[key];
						if (key == 'mkbId') this.objFilters.searchResult = this.getFilter[key];
						if (key == 'npsDecisionId') this.objFilters.searchResultNpc = this.getFilter[key];
						if (key == 'developerId') this.objFilters.searchResultDev = this.getFilter[key];
						if (key == 'startDate') this.objFilters.dateFrom = this.getFilter[key];
						if (key == 'finalDate') this.objFilters.dateTo = this.getFilter[key];
						if (key == 'mkbCodesId') this.objFilters.searchResultMkb = this.getFilter[key];
						if (key == 'ageGroupId') this.objFilters.ageGroups = this.getFilter[key];
						console.log(this.objFilters);
					}
					if (!Array.isArray(this.getFilter[key])) {
						this.isSearch = true;
						if (key == 'startDate') this.objFilters.dateFrom = this.getFilter[key];
						if (key == 'finalDate') this.objFilters.dateTo = this.getFilter[key];
					}
				}
			}

			let num = [];
			if (this.objOldFilters.filterNumber) {
				console.log(this.objOldFilters.filterNumber);
				this.objOldFilters.filterNumber
						.split(',')
						.forEach((el) => {
							if (el && el !== 0) num.push(+el);
						});
			}
				this.obj.name = null;
				this.obj.number = num;
				this.obj.stageId = stageId ? (Array.isArray(stageId) ? stageId : [stageId]) : null;
				this.obj.statusId = this.objOldFilters.statuses;
				this.obj.typeId = [];
				this.obj.serviceTypeId = this.objOldFilters.serviceTypes;
				this.obj.mkbId = this.objOldFilters.searchResult;
				(this.obj.expertiseResultId = []), (this.obj.npsDecisionId = this.objOldFilters.searchResultNpc);
				this.obj.developerId = this.objOldFilters.searchResultDev;
				this.obj.startDate = this.objOldFilters.dateFrom;
				this.obj.finalDate = this.objOldFilters.dateTo;
				this.obj.mkbCodesId = this.objOldFilters.searchResultMkb;
				this.obj.ageGroupId = this.objOldFilters.ageGroups;
				(this.obj.developerCompanyId = []), (this.obj.orderByColumn = orderByColumn ? orderByColumn : undefined), (this.obj.OffSetFetch = OffSetFetch ? OffSetFetch : undefined);
				console.log(this.obj.mkbId);


			this.apiAll({
				type: 'post',
				url: `api/Projects/filters`,
				obj: {
					OffSetFetch: OffSetFetch ? OffSetFetch : undefined,
					ageGroupId: this.obj.ageGroupId,
					developerCompanyId: [],
					developerId: this.obj.developerId,
					expertiseResultId: [],
					finalDate: this.obj.finalDate,
					mkbCodesId: this.obj.mkbCodesId ? this.obj.mkbCodesId.map((m) => m.id) : [],
					mkbId: this.obj.mkbId ? this.obj.mkbId.map((m) => m.id) : [],
					name: this.obj.name,
					npsDecisionId: this.obj.npsDecisionId,
					number: this.obj.number,
					orderByColumn: orderByColumn ? orderByColumn : undefined,
					serviceTypeId: this.obj.serviceTypeId,
					stageId: this.obj.stageId,
					startDate: this.obj.startDate,
					statusId: this.obj.statusId,
					typeId: []
				},
				id: undefined
			}).then((res) => {
				console.log(res.data);
				// сохранение фильтра
				this.changeFilter(this.obj);

				this.isLoading = false;
				this.searchBtnFunc = false;

				res.data.forEach((el) => {
					el.newFlag =
						el.roleListDisplayIsNewProject.length > 0 && el.roleListDisplayIsNewProject[0].forProjectAuthor && this.getUserInfo.id == el.createdBy
							? true
							: this.getUserInfo.role.id == (el.roleListDisplayIsNewProject[0] ? el.roleListDisplayIsNewProject[0].userRoleId : null)
							? true
							: false;
					el.serviceTypeId = el.serviceType ? el.serviceType.id : '';
					el.mkbName = el.mkb ? el.mkb.name : '';
					el.mkbCodesOne = el.mkbCodes.length > 0 ? el.mkbCodes[0].code : '';
					el.developerName = el.developer ? el.developer.name : '';
					el.statusName = el.status ? el.status.name : '';
					el.npcName = el.npsDecision ? el.npsDecision.name : '';
				});

				this.allStroke = res.headers[`${this.isSearch ? 'x-filtertotal-count' : 'x-total-count'}`];
				this.pageCount(+res.headers[`${this.isSearch ? 'x-filtertotal-count' : 'x-total-count'}`]);
				this.list = orderBy(res.data, 'newFlag', 'desc');
				this.viewText = false
			});
		},
		downloadReports() {
			let num = [];
			if (this.objOldFilters.filterNumber) {
				console.log(this.objOldFilters.filterNumber);
				this.objOldFilters.filterNumber
						.split(',')
						.forEach((el) => {
							if (el && el !== 0) num.push(+el);
						});
			}

			this.api
				.post(
					`${this.apiUrl}api/Projects/Report`,
					{
						number: num.length > 0 ? num : undefined,
						statusId: this.objFilters.statuses.length > 0 ? this.objFilters.statuses : undefined,
						serviceTypeId: this.objFilters.serviceTypes.length > 0 ? this.objFilters.serviceTypes : undefined,
						mkbId: this.objFilters.searchResult.length > 0 ? this.objFilters.searchResult.map((m) => m.id) : undefined,
						npsDecisionId: this.objFilters.searchResultNpc.length > 0 ? this.objFilters.searchResultNpc : undefined,
						startDate: this.objFilters.dateFrom ? this.objFilters.dateFrom : undefined,
						finalDate: this.objFilters.dateTo ? this.objFilters.dateTo : undefined,
						mkbCodesId: this.objFilters.searchResultMkb.length > 0 ? this.objFilters.searchResultMkb.map((m) => m.id) : undefined,
						ageGroupId: this.objFilters.ageGroups.length > 0 ? this.objFilters.ageGroups : undefined,
						developerId: this.objFilters.searchResultDev.length > 0 ? this.objFilters.searchResultDev : undefined
					},
					{ responseType: 'blob' }
				)
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Отчет.xlsx');
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => console.log(error));
		},
		filterStage(stage) {
			this.isLoading = true;
			this.pageNumber = 0;
			this.clickFilter = stage;

			if (stage !== 0) {
				this.apiMethods(null, { offsetRowCount: 0, fetchRowCount: this.size, use: true }, stage);
			} else {
				this.apiMethods(null, { offsetRowCount: 0, fetchRowCount: this.size, use: true }, null);
			}
		},
		nextPageEV(role, status, stage, id, developers) {
            // Если это МПНКО и компания авторизованного пользователя и КР совпадают то на просмотр
            let developers_ids = developers.map((m) => m.id);

            if(role === 1 && developers_ids.indexOf(this.authCompanyId) > -1) {
                this.$router.push(`/viewCR/${id}`);
                return;
            }


			if (role == 1 && status == 1 && stage == 1) this.$router.push(`/editCR/${id}`);
			else if (role == 1 && status == 6 && stage == 2) this.$router.push(`/editCR/${id}`);
			else if (role == 2 && status == 3 && stage == 1) this.$router.push(`/editCR/${id}`);
			else if (role == 2 && status == 11 && stage == 2) this.$router.push(`/editCR/${id}`);
			else if (role == 3 && status == 14 && stage == 3) this.$router.push(`/editCR/${id}`);
			else if (role == 4 && status == 17 && stage == 4) this.$router.push(`/editCR/${id}`);
			else if (role == 1 && status == 29 && stage == 6) this.$router.push(`/editCR/${id}`);
			else if (role == 3 && status == 24 && stage == 6) this.$router.push(`/editCR/${id}`);
			else if (role == 1 && status == 8 && stage == 2) this.$router.push(`/editCR/${id}`);
			else if (role == 6 && status == 36 && stage == 4) this.$router.push(`/editCR/${id}`);
			else if (role == 9 && status == 36 && stage == 4) this.$router.push(`/editCR/${id}`);
			else this.$router.push(`/viewCR/${id}`);
		},
		updateListEvent() {
			this.pageNumber = 0;

			let columnsItem = this.columns.find((f) => f.click);
			this.apiMethods(
				{
					columnName: columnsItem.name,
					orderOperation: columnsItem.sort ? 'asc' : 'desc'
				},
				{
					offsetRowCount: this.pageNumber !== 0 ? this.pageNumber * this.size : 0,
					fetchRowCount: this.size,
					use: true
				},
				this.clickFilter !== 0 ? this.clickFilter : null
			);
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			// console.log(this.maxYesterday);
			return local.toISOString().substr(0, 10);
		},
		validateDateVoting() {
			if (!this.dateVoting.date) {
                this.$notify({ group: 'foo', duration: 3000, type: 'error', title: 'Ошибка', text: 'Выберите дату!' });

                return;
            }

			this.showSaveDateWarning = true;
		},
		saveDateVoting() {
			this.showSaveDateWarning = false;
            console.log(this.dateVoting.date);

            this.api.post(`${this.apiUrl}api/Projects/votingdate?date=${this.dateVoting.date}`)
            .then(() => {
                this.dateVoting.isSaved = true;
            })
            .catch((error) => {
                if(error) {
                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Не найдено ни одного проекта, подходящего для указанной даты заседания' })
                }
            })
		}
	},
	mounted() {
		this.observer = new IntersectionObserver(this.infiniteScroll);
		this.observerMkb = new IntersectionObserver(this.infiniteScrollMkb);

		this.apiMethods(null, {
			offsetRowCount: 0,
			fetchRowCount: this.size,
			use: true
		});
	},
	created() {
		this.apiAll({ type: 'get', url: `api/Projects/getVotingDate` }).then((res) => {
            let dateVotingPlusFiveDay = new Date(res.data);
            dateVotingPlusFiveDay.setDate(dateVotingPlusFiveDay.getDate() + 5);

            // Если текущая дата больше даты заседания, нужно ее сбрасывать
            if(new Date() > dateVotingPlusFiveDay) {
                this.dateVoting.date = null;
			    this.dateVoting.isSaved = false;

                return;
            }

			this.dateVoting.date = res.data;
			this.dateVoting.isSaved = true;
		});

		this.apiAll({ type: 'get', url: `api/MKB?rowCount=20000`, id: undefined }).then((res) => {
			this.mkbList = res.data;
		});
		this.apiAll({ type: 'get', url: `api/NPSDecisions`, id: undefined }).then((res) => {
			this.npcList = res.data;
		});
		this.apiAll({ type: 'get', url: `api/Companies`, id: undefined }).then((res) => {
			this.сompaniesList = res.data;
		});
		this.apiAll({ type: 'get', url: `api/ServiceTypes`, id: undefined }).then((res) => {
			this.serviceTypesList = res.data;
		});
		this.apiAll({ type: 'get', url: `api/AgeGroups`, id: undefined }).then((res) => {
			this.ageGroupsList = res.data;
		});
		this.apiAll({ type: 'get', url: `api/Statuses`, id: undefined }).then((res) => {
			this.statusesList = res.data;
		});
		this.reset()
	}
};
</script>

<style>
@import '../../style/stylePages.css';
@import '../../views/ListCr/style/mobile.css';

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-option {
	white-space: normal;
}

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
	top: 42%;
}

.new-tag {
	float: right;
	width: 60px;
	height: 21px;
	background: #0da057;
	color: white;
	border-radius: 15px;
	text-align: center;
}

.old-tag {
	float: right;
	padding: 0 10px;
	height: 21px;
	background: red;
	color: white;
	border-radius: 15px;
	text-align: center;
}

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
	cursor: pointer;
}
</style>
